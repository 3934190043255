<template>
<div>
<!-- testimonails  -->
<section id="Testimonial" class="deep-purple darken-4" >
<v-container>
<div class="titleDiv px-5">
  <p class="display-1">Testimonials</p>
</div>

<v-row>
<v-col cols="12" sm="12" md="3" class="d-flex justify-center align-center">
   <img src="../../assets/images/sreekanth-pic.jpg" class="elevation-5" alt="avatar">
</v-col>
<v-col cols="12" sm="12" md="9">
<div class="py-5 mx-auto">
  <div>
    <p class="subtitle-1 font-italic">
      "Kalalaya academy is a place where I feel excited to be in .
      I gain a distinct experience that is incomparable . 
      I love the way English is taught and thus I love the language . 
      I'm so grateful for everything"</p>
  </div>
  <div>
    <p class="subtitle-2 font-italic"> - Mr Sreekanth Ramadas, Student of Senior Spoken English Batch </p>
  </div>
</div>
</v-col>
</v-row>

<v-row>
<v-col cols="12" sm="12" md="3" class="d-flex justify-center align-center">
   <img src="../../assets/images/ramdas-pic.jpg" class="elevation-5" alt="avatar">
</v-col>
<v-col cols="12" sm="12" md="9">
<div class="py-5 mx-auto">
  <div>
    <p class="subtitle-1 font-italic">
      "It was a wonderful evening that you provided to us yesterday. 
      The way you are moulding our kids is really commendable. 
      Further, you set new definition for the so called *Parent Teacher* meetings / interaction. 
      Your kids are showing the tendency of coming out of their shells and I am sure soon they all will really open up. 
      Though they enjoy a lot of freedom but i am sure you will teach them that the 
      *Freedom is not free and it is with responsibility* 
      Finally a special thanks to our friend Mr. Priyaranjan, 
      who came to our place just for two days completely spending his 
      days for Kalalaya like a student.  With his mere presence, 
      I am sure he taught our kids a valuable lesson the importance of social skills. 
      Though he works in America, he never kept that distance and behaved like any other kids. 
      Hats off to you gentleman teaching a valuable lesson to our kids.
      Congratulations to all those who are yet to figure in the awards list as you have more opportunities 
      to show better  results than the award winners. Of course the winners also deserves applause. 
      Keep it up Team Kalalaya and do better and bring good name to your institution and parents."</p>
  </div>
  <div>
    <p class="subtitle-2 font-italic"> - Mr Ramadas, A Parent of three Kalalaya students </p>
  </div>
</div>
</v-col>
</v-row>

<v-row>
<v-col cols="12" sm="12" md="3" class="d-flex justify-center align-center">
   <img src="../../assets/images/pramod-pic.jpg" class="elevation-5" alt="avatar">
</v-col>
<v-col cols="12" sm="12" md="9">
<div class="py-5 mx-auto">
  <div>
    <p class="subtitle-1 font-italic">
      "Kalalaya has to be appreciated well as the members are privileged to learn 
      'art - how to learn in addition to what to learn'. Opportunity for personality, 
      skill and attitude development is of course a great add on feature for their profession. 
      Almost the atmosphere resembles 'Gurukulam'. Best wishes"</p>
  </div>
  <div>
    <p class="subtitle-2 font-italic"> - Mr Pramod, A parent of Kalalaya student </p>
  </div>
</div>
</v-col>
</v-row>

<v-row class="mt-5">
<v-col cols="12" sm="12" md="3" class="d-flex justify-center align-center">
   <img src="../../assets/images/vishnu-pic.jpg" class="elevation-5" alt="avatar">
</v-col>
<v-col cols="12" sm="12" md="9">
<div class="py-5 mx-auto">
  <div>
    <p class="subtitle-1 font-italic">
      "Kalalaya academy gave me a sense of the importance and beauty of English. 
      They give me a lot of energy and confidence to speak English. 
      I enjoy studying in kalalaya academy. Above all, they love me a lot."</p>
  </div>
  <div>
    <p class="subtitle-2 font-italic"> - Mr Vishnu, Student of Senior Spoken English Batch </p>
  </div>
</div>
</v-col>
</v-row>

<v-row class="mt-5">
<v-col cols="12" sm="12" md="3" class="d-flex justify-center align-center">
   <img src="../../assets/images/vyshak-pic.jpg" class="elevation-5" alt="avatar">
</v-col>
<v-col cols="12" sm="12" md="9">
<div class="py-5 mx-auto">
  <div>
    <p class="subtitle-1 font-italic">
      "Kalalaya academy has been one of the strongest pillars in my life. And i realize the beauty of 
      English through Kalalaya"</p>
  </div>
  <div>
    <p class="subtitle-2 font-italic"> - Mr Vishak, Student of Senior Spoken English Batch </p>
  </div>
</div>
</v-col>
</v-row>


</v-container>
</section>

</div>
</template>

<script>
export default {
    name: 'testimonal'
}
</script>

<style scoped>
#Testimonial{
  padding-top: 7rem !important;
  padding-bottom: 5rem !important;
  color: #eee;
}
#Testimonial img{
  border-radius: 100px;
  height: 140px;
}
.titleDiv{
  border-left: 10px solid #eee;
  margin-bottom: 5rem;
}
</style>